const globalData = {
  companyID: `7`,
  departmentID: `13`,
  baseUrl: "https://api-xeniatoken.onrender.com/api/",
  image_render: "https://apiimage.stslive.in/TMS/assest/uploads?fileName=",
  domain: "https://api-xeniatoken.onrender.com/",
  /* 1 for showing Call and Hold, 0 for showing Call only */
  isCall: "1",
};

export default globalData;
